import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { drawerWidth } from './Drawer'

const useStyles = makeStyles((theme: Theme) => createStyles({}))
interface IHeader {
  onDrawerOpen: any
}
const Header = (props: IHeader) => {
  const { onDrawerOpen, ...rest } = props
  const classes = useStyles()
  const IsCheckIn = JSON.parse(localStorage.getItem('CheckIn'))?.CheckIn

  return (
    <AppBar {...rest} position="fixed" className="desktop-header">
      <Toolbar>
        {!IsCheckIn ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <span className="title">
          {process.env.REACT_APP_API_URL == 'prod-eon-build' ||
          process.env.REACT_APP_API_URL == 'uat-eon-build'
            ? 'EON'
            : 'IFCA HRX'}
        </span>
      </Toolbar>
    </AppBar>
  )
}
export default Header
