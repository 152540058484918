import { yupResolver } from '@hookform/resolvers'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
} from '@material-ui/core'
import DefaultCompanyIcon from '@ifca-root/react-component/src/assets/images/defaultcompanyicon.svg'
import { Check, Visibility, VisibilityOff } from '@material-ui/icons'
import {
  getRegistrationToken,
  setAccessToken,
} from '@hr-root/hr-employee-react/src/AccessToken'

import Logo from 'assets/images/logo.png'
import EonLogo from '@ifca-root/react-component/src/assets/images/EON_LOGO.png'
import SehsaLogo from '@ifca-root/react-component/src/assets/images/SEHSA_LOGO.svg'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  SoftwareCode,
  useLoginSsoLazyQuery,
  useSelectProfileLazyQuery,
  useEmployeeShiftGroupAssignmentLogLazyQuery,
  useMyEmployeeInfoLazyQuery,
} from '@hr-root/hr-employee-react/src/generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { LoginSchema } from 'yupValidation/yup'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import { CopyRight } from 'components/AuthLayout/CopyRight'
import { useLoginMutation } from '@hr-root/hr-employee-react/src/generated/graphql'
import { CryptoHelper } from '@hr-root/hr-employee-react/src/containers/helper/Crypto/Crypto'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  msalConfig,
  loginRequest,
} from '@hr-root/hr-employee-react/src/containers/Authentication/authConfig'
import { SwitchCompanyDialog } from 'components/Dialog/SwitchCompanyDialog'
import { ListItemAvatar } from '@mui/material'
import qrIcon from '@ifca-root/react-component/src/assets/icons/qr-header.svg'

interface ILoginForm {
  email: string
  password: string
  showPassword: boolean
  rememberMe: boolean
  product: string
}
export const Login = props => {
  const rememberme: any = localStorage.getItem('rememberme')
  localStorage.clear()
  if (rememberme !== null) {
    localStorage.setItem('rememberme', rememberme)
  }
  let history = useHistory()

  const checksso = window?.location?.href?.split('/')[3]

  const [hasMultiProfile, setHasMultiProfile] = useState(false)
  const [profileListing, setProfileListing] = useState([])
  const [inputData, setData] = useState(null)
  const [extraLoading, setExtraLoading] = useState(false)
  const [switchCompanyID, setSwitchCompanyID] = useState(null)
  const [selectedSub, setSelectedSub] = useState(null)
  const [isCheckIn, setIsCheckIn] = useState(false)
  const [Prefix, setPrefix] = useState('')
  const [Url, setUrl] = useState('')

  function LoginSuccess(user) {
    localStorage.setItem('loginInfo', JSON.stringify(user))
    let url = ''
    var prefix = ''
    switch (process.env.REACT_APP_API_URL) {
      case 'ifca-build':
        url = 'ifcahome.hrx.asia'
        prefix = 'https'
        break
      case 'prod-v5-build':
        url = 'hrms.hrx.asia'
        prefix = 'https'
        break
      case 'prod2-v5-build':
        url = 'hr.hrx.asia'
        prefix = 'https'
        break
      case 'prod-clone-build':
        url = 'hrms-test.hrx.asia'
        prefix = 'https'
        break
      case 'uat-v5-build':
        url = 'hrms-uat.hrx.asia'
        prefix = 'https'
        break
      case 'uat2-v5-build':
        url = 'hr-uat.hrx.asia'
        prefix = 'https'
        break
      case 'dev-v2-build':
        url = 'hrx-dev-employee.ifca.io'
        prefix = 'https'
        break
      case 'demo-build':
        url = 'hrms-demo.hrx.asia'
        prefix = 'https'
        break
      case 'prod-mbi-build':
        url = 'smarthrx.mbiselangor.com.my'
        prefix = 'https'
        break
      case 'uat-mbi-build':
        url = 'smarthrx-uat.mbiselangor.com'
        prefix = 'https'
        break
      case 'prod-sehsa-build':
        url = '172.17.10.95'
        prefix = 'http'
        break
      case 'prod-sehuk-build':
        url = '172.16.130.119'
        prefix = 'http'
        break
      case 'prod-eon-build':
        url = 'employee.eon.com.my'
        prefix = 'https'
        break
      case 'uat-eon-build':
        url = 'employee-uat.eon.com.my'
        prefix = 'https'
        break
      case 'prod-ideal-build':
        url = '210.19.68.241:1117'
        prefix = 'http'
        break
      default:
        url = 'localhost:3010'
        prefix = 'http'
    }
    setUrl(url)
    setPrefix(prefix)

    // HRX-3964 - check if user click check in button
    if (isCheckIn) {
      // set local storage
      if (user?.employeeID) {
        getEmployeeSummary({
          variables: {
            EmployeeID: user?.employeeID,
          },
        })
      } else {
        setOpenAlert(true)
        setMessage('No Shift Group Assigned')
        setExtraLoading(false)
      }
    } else {
      window.location.href = `${prefix}://${url}/home`
    }
  }

  const [
    getEmployeeSummary,
    { data: { MyEmployeeInfo } = { MyEmployeeInfo: null } },
  ] = useMyEmployeeInfoLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      // call api to check if the employee got shift
      // if (data?.MyEmployeeInfo?.Contact?.ContactID) {
      //   EmployeeShiftGroupAssignment({
      //     variables: {
      //       ContactID: data?.MyEmployeeInfo?.Contact?.ContactID,
      //       Today: true,
      //     },
      //   })
      // } else {
      //   setOpenAlert(true)
      //   setMessage('Employee not found')
      //   setExtraLoading(false)
      // }

      // temp solution for 3964
      if (data?.MyEmployeeInfo) {
        EmployeeShiftGroupAssignment({
          variables: {
            EmployeeID: data?.MyEmployeeInfo?.EmployeeID,
            Today: true,
          },
        })
      } else {
        setOpenAlert(true)
        setMessage('Employee not found')
        setExtraLoading(false)
      }
    },
  })

  const [
    EmployeeShiftGroupAssignment,
    {
      data: { EmployeeShiftGroupAssignmentLog } = {
        EmployeeShiftGroupAssignmentLog: null,
      },
    },
  ] = useEmployeeShiftGroupAssignmentLogLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      // set local storage
      localStorage.setItem(
        'Employee',
        JSON.stringify(
          Object.assign(MyEmployeeInfo, {
            Salutation: MyEmployeeInfo?.Contact?.EmployeeSalutation?.Name || '',
          })
        )
      )
      localStorage.setItem(
        'CheckIn',
        JSON.stringify(Object.assign({ CheckIn: true }))
      )
      // check shift
      const hasCheckIn = data?.EmployeeShiftGroupAssignmentLog?.some(
        log =>
          log.ShiftGroup?.ByQR === true || log.ShiftGroup?.ByLocation === true
      )
      if (hasCheckIn) {
        // got shift then only go to check in page
        window.location.href = `${Prefix}://${Url}/Employee/tms/checkIn`
      } else {
        setOpenAlert(true)
        setMessage('No Shift Group Assigned')
        setExtraLoading(false)
      }
    },
  })

  const [
    getProfile,
    { data, loading: getProfileLoading, error },
  ] = useSelectProfileLazyQuery({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      if (data?.SelectProfile?.length > 1) {
        setProfileListing(data?.SelectProfile)
        setHasMultiProfile(true)
      } else {
        login({
          variables: {
            loginId: inputData?.email,
            softwareCode: SoftwareCode.Hr,
            password: encryptMessage(inputData?.password),
          },
        })
      }
    },
    fetchPolicy: 'no-cache',
  })

  const [login, { loading }] = useLoginMutation({
    onError: error => {
      setExtraLoading(false)
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    update: (store, { data }) => {
      if (!data) {
        return null
      }

      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data.login.user as any,
        },
      })
    },
    onCompleted: data => {
      if (data && data.login.accessToken) {
        setAccessToken(data.login.accessToken)
      }
      if (rememberMe) {
        let rememberme = {
          username: getValues('email'),
          password: getValues('password'),
        }
        localStorage.setItem('rememberme', JSON.stringify(rememberme))
      } else {
        localStorage.removeItem('rememberme')
      }
      console.log(data?.login?.user)

      setTimeout(() => {
        LoginSuccess(data?.login?.user)
      }, 500)
    },
  })

  const [values, setValues] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(
    rememberme !== null ? true : false
  )

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/profile')
  }
  const MESSAGE = 'Password Changed Successfully'

  const handleClickShowPassword = () => {
    setValues(!values)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleCheckRememberMe = (checked: boolean) => {
    setRememberMe(checked)
  }

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control, getValues, reset } = useForm<
    ILoginForm
  >({
    defaultValues: {
      email: rememberme !== null ? JSON.parse(rememberme)?.username : '',
      password: rememberme !== null ? JSON.parse(rememberme)?.password : '',
    },
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = data => {
    setExtraLoading(true)
    setData(data)
    getProfile({
      variables: {
        loginId: data?.email,
        softwareCode: SoftwareCode.Hr,
      },
    })
  }

  const [
    loginSSOQuery,
    { data: data1, loading: loading1, error: error1 },
  ] = useLoginSsoLazyQuery({
    onCompleted: data => {
      if (data) {
        if (data && data.loginSSO.token) {
          setAccessToken(data.loginSSO.token)
          console.log(data.loginSSO.token)
          console.log('Access token set')
        }
        console.log(data?.loginSSO)
        LoginSuccess(data?.loginSSO?.user)
      }
    },
    onError: error => {
      console.log(error?.message)

      setMessage('Error : ' + error?.message)

      setOpenAlert(true)
    },
  })

  const submitSSOLogin = (token: string) => {
    loginSSOQuery({
      variables: {
        token: token,
        deviceToken: getRegistrationToken(),
      },
    })
  }

  const handleLogin = async () => {
    try {
      const msalInstance = new PublicClientApplication(msalConfig)
      const result = await msalInstance.loginPopup(loginRequest)
      if (result) {
        // console.log(result.accessToken)
        submitSSOLogin(result.accessToken)
        // console.log(result.account)
        // setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Authentication error:', error)
    }
  }

  const manageProfile = id => {
    login({
      variables: {
        loginId: inputData?.email,
        softwareCode: SoftwareCode.Hr,
        password: encryptMessage(inputData?.password),
        SubscriptionAccountID: id,
      },
    })
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Login Error',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SwitchCompanyDialog
        fullWidth={true}
        open={hasMultiProfile}
        onClose={() => {
          setHasMultiProfile(false)
          setExtraLoading(false)
        }}
        sections={{
          header: {
            title: 'Select Profile',
          },
          body: () => (
            <div>
              <div style={{ height: '8px' }} />
              {profileListing.map(i => (
                <>
                  <ListItem
                    key={i.CompanyID}
                    onClick={() => {
                      setSelectedSub(i.SubscriptionAccountID)
                      setSwitchCompanyID(i.CompanyID)
                    }}
                    button
                    className="b-b p-l-12 flex"
                    style={{
                      backgroundColor:
                        i.CompanyID === switchCompanyID ? '#FFF1DD' : '',
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          i?.Documents?.find(
                            obj => obj?.DocumentType === 'Company'
                          )?.DocumentFile ?? DefaultCompanyIcon
                        }
                        alt={`${i.CompanyName}'s logo`}
                      />
                    </ListItemAvatar>
                    <span
                      className={`fs-14 fw-600 ${
                        i.CompanyID === switchCompanyID ? 'flex-space' : ''
                      }`}
                    >
                      {i.CompanyName}
                    </span>
                    {i.CompanyID === switchCompanyID && (
                      <Check
                        color="primary"
                        aria-label={`Switch to ${i.CompanyName}`}
                      />
                    )}
                  </ListItem>
                </>
              ))}
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setHasMultiProfile(false)
                    setData(null)
                    setExtraLoading(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    manageProfile(selectedSub)
                    setHasMultiProfile(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout
        logo={
          ['prod-eon-build', 'uat-eon-build']?.includes(
            process.env.REACT_APP_API_URL
          )
            ? EonLogo
            : process.env.REACT_APP_API_URL == 'prod-sehsa-build'
            ? SehsaLogo
            : Logo
        }
        image={Background}
      >
        <span className="page-title">Login</span>
        <div className="form-box">
          <Controller
            as={TextField}
            fullWidth
            helperText={errors?.email?.message}
            error={errors?.email ? true : false}
            autoComplete="off"
            name="email"
            label="Mobile No. / Email"
            control={control}
            ref={register}
          />
          <Grid container justifyContent="flex-start">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.password?.message}
              error={errors?.password ? true : false}
              autoComplete="off"
              name="password"
              label="Password "
              type={values ? 'text' : 'password'}
              value={getValues('password')}
              //   onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              control={control}
              ref={register}
            />
          </Grid>

          <div className="other-auth">
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={e => handleCheckRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              ref={register}
              label="Remember Me"
            />
            <span
              className="forget-link"
              onClick={() => history.push('/forgot-password')}
            >
              Forgot Password?
            </span>
          </div>

          <Button
            // type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
            disabled={extraLoading}
            onClick={() => {
              setIsCheckIn(false)
              handleSubmit(onSubmit)()
            }}
          >
            {!extraLoading ? (
              'Login'
            ) : (
              <CircularProgress size={'1.5rem'} style={{ color: 'white' }} />
            )}
          </Button>
          {checksso == 'login-sso' ? (
            <Button
              onClick={handleLogin}
              variant="contained"
              color="primary"
              className="login-btn"
            >
              {' '}
              Login with Microsoft
            </Button>
          ) : null}
          <Button
            // type="submit"
            variant="contained"
            style={{ width: 'auto', backgroundColor: 'transparent' }}
            className="login-btn"
            disabled={extraLoading}
            onClick={() => {
              setIsCheckIn(true)
              handleSubmit(onSubmit)()
            }}
          >
            {!extraLoading ? (
              <IconButton
                style={{ backgroundColor: '#FF9800' }}
                type="submit"
                className="second-btn"
              >
                <img
                  src={qrIcon}
                  style={{
                    filter: 'invert(1)',
                    width: '30px',
                    padding: '10px',
                  }}
                />
              </IconButton>
            ) : (
              <CircularProgress size={'1.5rem'} style={{ color: 'white' }} />
            )}
          </Button>
        </div>
        <CopyRight />
      </AuthLayout>
    </>
  )
}
